<template>
  <div class="container">
    <van-loading class="loading" color="#9d1d22" text-color="#9d1d22" v-if="loading" size="24px">加载中...</van-loading>
    <div v-if="loading == false">
      <van-sticky>
        <van-nav-bar :title="title" left-arrow @click-left="goBack()">
        </van-nav-bar>
      </van-sticky>

      <h2 class="van-doc-demo-block__title">{{ this.dealer.name }}
      </h2>

      <van-cell-group inset class="van-cell-group--inset">
        <div class="info-list">
          <van-row>
            <van-col span="8">{{ dealer.center }}</van-col>
            <van-col span="8">{{ dealer.department }}</van-col>
            <van-col span="8">{{ dealer.city }}{{ dealer.county }}</van-col>
          </van-row>
          <van-row>
            <van-col span="8">活动总数：{{ dealer.campaignCount }}</van-col>
            <van-col span="8">活动人数：{{ dealer.comeCount }}</van-col>
            <van-col span="8">终端总数：{{ dealer.customerCount }}</van-col>
          </van-row>
          <van-row>
            <van-col span="8">活动总费用：{{ dealer.totalCost }}</van-col>
            <van-col span="8">动销总金额：{{ dealer.orderAmount }}</van-col>
            <van-col span="8">跟进销售：{{ dealer.followSale }}</van-col>
          </van-row>
          <van-row>
            <van-col span="24">资源：{{ dealer.resource }}</van-col>
          </van-row>


        </div>
        <div style="margin: 16px; text-align: center;">
          <van-button plain type="info" round size="small" :to="'/dealer/edite?para=' + dealer.id">完善客户</van-button>
        </div>
      </van-cell-group>

      <h2 class="van-doc-demo-block__title">活动列表
      </h2>
      <van-cell-group inset class="van-cell-group--inset">
        <div style="margin: 16px; text-align: center;">
          <van-button plain type="info" round size="small"
            :to="'/dealer/campaigndetail?dealerId=' + dealer.id">添加活动</van-button>
        </div>
        <div class="campaign-list">
          <ul v-if="campaigns.length > 0">
            <van-list v-model="upLoading" :finished="finished" @load="onLoad" finished-text="没有更多了">

              <li v-for="campaign in campaigns" :key="campaign.id">


                <div class="campaign-item">
                  <div class="item-details">
                    <div class="item-row">
                      <div class="item-valueweight">{{ `${DateFromat(campaign.date)} ${campaign.typeName}` }}
                      </div>
                      <div class="item-value"></div>
                    </div>
                    <div class="item-row">
                      <div class="item-value">{{ campaign.address }}</div>
                    </div>

                    <div class="item-row">
                      <div class="item-row">
                        <div class="item-label">业务员：</div>
                        <div class="item-value">{{ campaign.mainSale }}</div>
                      </div>
                      <div class="item-row" style="margin-left: 25px;">
                        <div class="item-label">品鉴师：</div>
                        <div class="item-value">{{ campaign.helpSale }}</div>
                      </div>
                    </div>



                    <div class="item-row">
                      <div class="item-row">
                        <div class="item-label">到场人数：</div>
                        <div class="item-value">{{ campaign.comeCount }}</div>
                      </div>
                      <div class="item-row" style="margin-left: 25px;">
                        <div class="item-label">总费用：</div>
                        <div class="item-value">{{ campaign.totalCost }}</div>
                      </div>
                    </div>



                    <div class="item-row">
                      <div class="item-row">
                        <div class="item-label">终端数：</div>
                        <div class="item-value">{{ campaign.customerCount }}</div>
                      </div>
                      <div class="item-row" style="margin-left: 25px;">
                        <div class="item-label">动销金额：</div>
                        <div class="item-value">{{ campaign.orderAmount }}</div>
                      </div>
                    </div>


                  </div>
                  <div class="item-action">
                    <van-button plain type="info" round size="mini"
                      :to="`/dealer/campaigndetail?id=${campaign.id}`">完善活动</van-button>

                      <div style="margin-top: 25px;" v-if="campaign.canDelete">
                      <van-button plain type="info" round size="mini" @click="deleteCampaign(campaign)"
                      >删除活动</van-button>
                    </div>
                  </div>
                </div>



                <!-- <div class="campaign-item">
                <div class="item-date">{{ DateFromat(campaign.date) }}</div>
                <div class="item-type">活动类型：{{ campaign.typeId == 1 ? "品鉴会" : "" }}</div>
                <div class="item-sales">
                  <div class="item-salesman">销售：{{ campaign.mainSale }}</div>
                  <div class="item-taster">品鉴师：{{ campaign.helpSale }}</div>
                </div>
                <div class="item-address">{{ campaign.address }}</div>
                <div class="item-action">
                  <van-button plain type="info" round size="mini"
                    :to="`/dealer/campaigndetail?id=${campaign.id}`">完善活动信息</van-button>
                </div>
              </div> -->


              </li>
            </van-list>
          </ul>
          <van-empty v-if="campaigns.length == 0" description="暂无活动" image="/image/empty-image-default.png" />
        </div>
      </van-cell-group>



    </div>
  </div>
</template>
  
<script>
import { GetDealer, GetDealerCampaignList,deleteDealerCampaign } from "@/api/dealer";
import { Toast } from 'vant';
//import { Toast } from 'vant';

export default {
  name: "znarticledetail",
  data() {
    return {
      loading: true,
      title: "",
      dealer: {},
      campaigns: [],
      upLoading: false,
      finished: false,
      page: {
        current: 1,
        pageSize: 10,
        sorterType: 1,
        fromType: 1,
        filterData: {
          dealerId: "",
        },
      },

    };
  },
  components: {

  },
  updated() {
  },
  created() {
    this.init();
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化
      if (to.path == "/dealer/dealercampaign") {
        if (to.query != from.query) {
          this.loading = true;
          this.init();
        }
      }
    },
  },
  mounted() { },

  methods: {

    DateFromat(data) {
      if (!data) {
        return "";
      }

      return data.replace(" 00:00:00", "").replace("T00:00:00", "");
    },
    TypeForamt(data) {
      return data === 1 ? '品鉴会' : ''
    },
    goBack() {
      if (this.$route.query.back) {
        this.$router.back();
      }
      else {
        this.$router.replace({ path: '/dealer'})
      }
    },

    async deleteCampaign(item) {
      // 
      const camtitle= `${this.DateFromat(item.date)} ${item.typeName}`
      await this.$dialog.confirm({
        title: "删除确认",
        message: "确认要删除 "+camtitle+" 此次活动吗",
      });

      let aresult=  await deleteDealerCampaign(item);
      if(aresult.data.success){
        Toast("删除成功");
        await this.init();
      }
      else{
        Toast("删除失败，请重试");
      }
    },

    async init() {
      let para = this.$route.query.para;
      this.page.filterData.dealerId = para;
      this.campaigns = [];  
      this.page.current = 1;  


      let aresult = await GetDealer(para); //({ ...this.page })
      this.dealer = aresult.data.data;
      document.title = this.dealer.name;
      this.title = this.dealer.name;
      await this.onLoad();

      this.loading = false;

    },
    async onLoad() {

      if (this.page.filterData.dealerId === "") {
        return;
      }
      let cresult = await GetDealerCampaignList(this.page);
      this.campaigns.push(...cresult.data.data);
      this.upLoading = false;
      if (this.page.current * this.page.pageSize < cresult.data.total) {
        this.page.current++;
      } else {
        this.finished = true;
      }


    },
  },
};
</script>
  
  
<style lang="less" scoped>
.loading {
  text-align: center;
}

.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  background-color: #f7f8fa;
}

.van-cell-group--inset {
  margin: 0 16px 0px 16px;
  overflow: hidden;
  border-radius: 8px;
}

.van-doc-demo-block__title {
  margin: 0;
  padding: 32px 16px 16px;
  color: rgba(69, 90, 100, 0.6);
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}

.detail {
  padding: 10px 10px 20px;
}

.info-list {
  padding: 5px;

  /deep/ li {
    line-height: 24px;
  }

}

.campaign-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
  background-color: #fdfdfd;
}

.item-details {
  flex: 1;
}

.item-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.item-label {
  font-weight: bold;
  font-size: 14px;
  width: 70px;
}

.item-value {
  font-size: 14px;
}

.item-valueweight {
  font-weight: bold;
  font-size: 16px;
}

.item-action {
  margin-left: 10px;
}
</style>